import React from 'react';
import { useRoutes } from 'hookrouter';
import './App.scss';
import Visual from './Visual/Visual'

const routes = {
	'/': () => <Visual />,
	'/:visualId': ({visualId}) => <Visual visualId={visualId} />
};

function App() {
	
	console.log("v1.03")
	const routeResult = useRoutes(routes);

  return (
    <div className="App">
			{ routeResult }
		</div>
  );
}

export default App;
