import React, { useState } from 'react'
import axios from 'axios'

export default function Visual({ visualId }) {

	const port = (window.location.port == "") ? "" : ":" + window.location.port
	const resourceUrl = `${window.location.protocol}//${window.location.hostname}${port}/visuals/${visualId}/home.html`
	const [visualUrl, setVisualUrl] = useState("")
	const [visualName, setVisualName] = useState("")
	
	if(visualUrl === "") {
		axios.get(resourceUrl).then((response) => {
			console.log("Found Visual URL.")
			setVisualUrl(resourceUrl)
			setVisualName(window.location.pathname.substring(1).replace('-',' '))
		}).catch((error) => {
			console.log("No Visual URL found.")
			setVisualUrl("https://app4takeaways.com")
		})
	}

	return (
		<div className="visual">
			
			<h3>{visualName}</h3>

			<div className="phoneContainer">
				<div className="antenna"></div>
				<div className="visual-container-outer">
					<div className="visual-container-inner scroll no-scrollbar">
						<iframe title="AppVisual" frameBorder="0" src={visualUrl}>This demo cant be displayed.</iframe>
					</div>
				</div>
				<div className="homeButton"></div>
			</div>
			
		</div>
	)
}
